import Axios from 'axios';
import Service from './Service';

async function get() {
  return await Service.execute(
    Axios.get(
      `${Service.API_URL}/v1/api/companies/${await Service.getCompanyId()}`
    )
  );
}

async function getByName(companyName: string) {
  if (companyName.startsWith('localhost:')) {
    companyName = 'medicheck';
  }
  return await Service.execute(
    Axios.get(`${Service.API_URL}/v1/api/companies/${companyName}`)
  );
}

export default { get, getByName };
